import React, { createContext, FC, PropsWithChildren } from 'react';

import useGeo, { GEO } from '../hooks/useGeo';

type TData = { geo: GEO | null };

export const CommonContext = createContext<TData>({ geo: null });

const CommonProvider: FC<PropsWithChildren> = ({ children }) => {
  const geo = useGeo();

  return <CommonContext.Provider value={{ geo }}>{children}</CommonContext.Provider>;
};

export default CommonProvider;
